import * as React from "react";
import SimpleReactLightbox from "simple-react-lightbox";

import Footer from "../Footer";
import Header from "../Header";
import "../../styles/styles.scss";

type PageLayoutProps = {
  children: React.ReactNode;
  layout?: string;
  pathname?: string;
};

const PageLayout = ({ children, layout, pathname }: PageLayoutProps) => (
  <>
    <SimpleReactLightbox>
      <Header />
      <div className="page-layout__wrapper">{children}</div>
      <Footer />
    </SimpleReactLightbox>
  </>
);

export default PageLayout;
