import * as React from "react";

import { PMLogo } from "../../utils/vectors";

const Footer = () => (
  <footer className="footer">
    <div className="footer__top">
      <PMLogo size={160} />
      <div className="footer__contact">
        <h4>Papa Monkey Custom Bikes</h4>
        <p>
          <a href="mailto:bill@papamonkeybikes.com">bill@papamonkeybikes.com</a>
          <br />
          <a href="tel:+1-419-852-3455">419-852-3455</a>
          <br />
          419 S. Main
          <br />
          Celina, OH 45822
        </p>
      </div>
    </div>
    <div className="footer__bottom">
      <p>© Papa Monkey Bicycles {new Date().getFullYear()}</p>
    </div>
  </footer>
);
export default Footer;
