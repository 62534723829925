import React from "react";

const getPaths = (title: string): JSX.Element => {
  switch (title) {
    case "Moon Icon":
      return (
        <path d="M474.35 290.33c-26.85 178-247.27 252.92-377.26 122.93-138.47-138.47-41-373 151.18-378.67l33.92-1.42L261 60c-93.25 125.75 53.69 291.07 189.34 213.36l29.67-17zm-39.56 26.85c-148.37 49.45-285.42-106-217.6-247.27C71.65 99.58 13.72 283.27 121.11 390.65 220 491 389.57 448.58 434.79 317.18z" />
      );
    case "Sun Icon":
      return (
        <>
          <path d="M256 148.45A107.55 107.55 0 10363.55 256 107.68 107.68 0 00256 148.45zM256 130.68a14.43 14.43 0 0014.41-14.41V46.41a14.41 14.41 0 00-28.82 0v69.86A14.43 14.43 0 00256 130.68z" />
          <path d="M256 381.32a14.43 14.43 0 00-14.41 14.41v69.86a14.41 14.41 0 0028.82 0v-69.86A14.43 14.43 0 00256 381.32zM465.59 241.59h-69.86a14.41 14.41 0 000 28.82h69.86a14.41 14.41 0 100-28.82zM130.68 256a14.43 14.43 0 00-14.41-14.41H46.41a14.41 14.41 0 100 28.82h69.86A14.43 14.43 0 00130.68 256zM354.65 173.58a14.33 14.33 0 0010.18-4.21l49.43-49.45a14.41 14.41 0 00-20.42-20.33L344.47 149a14.41 14.41 0 0010.18 24.58zM158.64 339.15a14.32 14.32 0 00-10.15 4.19l-49.5 49.54a14.41 14.41 0 0020.42 20.32l49.39-49.41a14.42 14.42 0 00-10.11-24.64zM148.43 169.37A14.41 14.41 0 00168.8 149l-49.49-49.46A14.41 14.41 0 0099 120zM364.85 343.35l-.05-.05a14.41 14.41 0 00-20.32 20.42l49.42 49.47a14.36 14.36 0 0010 4.05h.26a14.4 14.4 0 0010.06-24.46z" />
        </>
      );
    case "Facebook Mark":
      return (
        <path d="M290.83 480V276h69.66l9.95-79.6h-79.61v-49.74c0-22.39 7.45-39.79 39.79-39.79h42.28V34.72c-9.91 0-34.8-2.5-62.14-2.5a96 96 0 00-102 104.49v59.69H139.1V276h69.66v204z" />
      );
    case "Left Arrow":
      return (
        <path d="M36 246.43a13.54 13.54 0 000 19.14l114.8 114.79a13.53 13.53 0 1019.13-19.13l-91.7-91.7h388.26a13.55 13.55 0 000-27.1H78.23l91.7-91.7a13.53 13.53 0 10-19.13-19.13z" />
      );
    case "Right Arrow":
      return (
        <path d="M476 265.57a13.54 13.54 0 000-19.14L361.24 131.64a13.53 13.53 0 10-19.13 19.13l91.7 91.7H45.55a13.55 13.55 0 000 27.1h388.26l-91.7 91.7a13.53 13.53 0 1019.13 19.13z" />
      );
    case "Next Arrow":
      return (
        <path d="M464.53 234.13L262.4 32l-43.74 43.75L368 225.06H38.4v61.88H368L218.66 436.25 262.4 480l202.13-202.13a30.91 30.91 0 000-43.74z" />
      );
    case "Previous Arrow":
      return (
        <path d="M47.47 277.87L249.6 480l43.74-43.75L144 286.94h329.6v-61.88H144L293.34 75.75 249.6 32 47.47 234.13a30.91 30.91 0 000 43.71z" />
      );
    case "Instagram Mark":
      return (
        <path d="M255.94 141.07A114.93 114.93 0 10370.87 256a114.77 114.77 0 00-114.93-114.93zm0 189.63a74.7 74.7 0 1174.7-74.7 74.86 74.86 0 01-74.7 74.7zm119.64-221.12a26.8 26.8 0 1010.27 2 26.81 26.81 0 00-10.27-2zM480 256c0-30.93.28-61.58-1.46-92.46-1.74-35.86-9.92-67.69-36.15-93.92s-58.05-34.41-93.91-36.14C317.52 31.74 286.87 32 256 32s-61.59-.28-92.46 1.46c-35.87 1.73-67.7 9.92-93.92 36.14s-34.41 58.06-36.15 93.92C31.74 194.47 32 225.12 32 256s-.28 61.58 1.45 92.46c1.74 35.86 9.92 67.69 36.15 93.92s58.05 34.4 93.92 36.14c30.95 1.74 61.6 1.48 92.48 1.48s61.58.28 92.46-1.46c35.86-1.74 67.69-9.92 93.91-36.14s34.41-58.06 36.15-93.92c1.79-30.9 1.48-61.55 1.48-92.48zm-49.34 132.14a76.43 76.43 0 01-42.58 42.58c-29.48 11.71-99.47 9.08-132.14 9.08s-102.72 2.63-132.19-9a76.39 76.39 0 01-42.59-42.59c-11.65-29.53-9-99.52-9-132.19s-2.63-102.72 9-132.19a71.26 71.26 0 0116.92-25.69 72.57 72.57 0 0125.67-16.92c29.47-11.65 99.52-9 132.19-9s102.72-2.63 132.19 9a71.26 71.26 0 0125.67 16.92 71.77 71.77 0 0116.92 25.67c11.65 29.47 9 99.52 9 132.19s2.65 102.66-9.06 132.14z" />
      );
    case "Stacked Dots Icon":
      return (
        <>
          <circle cx="240" cy="96" r="48" />
          <circle cx="240" cy="256" r="48" />
          <circle cx="240" cy="416" r="48" />
        </>
      );
    case "Info Icon":
      return (
        <>
          <circle cx="256" cy="164.91" r="31.11" />
          <path d="M281.88 250.15v102.54a25.88 25.88 0 11-51.76 0V250.15a25.88 25.88 0 1151.76 0z" />
          <path d="M256 32C132.29 32 32 132.29 32 256s100.29 224 224 224 224-100.29 224-224S379.71 32 256 32zm130.23 354.23c-71.31 72.54-187.92 73.53-260.46 2.22S52.24 200.53 123.55 128l2.22-2.22c71.31-72.54 187.92-73.53 260.46-2.22s73.53 187.91 2.22 260.44q-1.09 1.12-2.22 2.23z" />
          <path d="M256 32C132.29 32 32 132.29 32 256s100.29 224 224 224 224-100.29 224-224S379.71 32 256 32zm130.23 354.23c-71.31 72.54-187.92 73.53-260.46 2.22S52.24 200.53 123.55 128l2.22-2.22c71.31-72.54 187.92-73.53 260.46-2.22s73.53 187.91 2.22 260.44q-1.09 1.12-2.22 2.23z" />
        </>
      );
    case "Menu Icon":
      return (
        <>
          <rect x="32" y="96" width="448" height="64" rx="14.11" />
          <rect x="32" y="224" width="448" height="64" rx="14.11" />
          <rect x="32" y="352" width="448" height="64" rx="14.11" />
        </>
      );
    case "Button Arrow":
      return (
        <path d="M470.84 233.9L262.47 25.53l-44.2 44.2 155 155H32v62.52h341.29l-155 155 44.2 44.2L470.84 278.1a31.25 31.25 0 000-44.2z" />
      );
    case "Close Icon":
      return (
        <path d="M301.47 256.22L470.84 86.84a32 32 0 00-45.25-45.25L256.22 211 86.84 41.59a32 32 0 00-45.25 45.25L211 256.22 41.59 425.59a32 32 0 1045.25 45.25l169.38-169.37 169.37 169.37a32 32 0 0045.25-45.25z" />
      );

    default:
      return <></>;
  }
};

export default getPaths;
