import React from "react";
import { Link } from "gatsby";

import Icon from "../Icon";

type NavProps = {
  wrapperClass?: string;
  closeModal?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};
const Nav = ({ wrapperClass, closeModal }: NavProps) => (
  <nav className={`header__nav ${wrapperClass}`}>
    <div className="header__mobile-close">
      <button onClick={closeModal}>
        <Icon title="Close Icon" size={32} />
      </button>
    </div>
    <ul>
      <li>
        <Link to="/about">About</Link>
      </li>
      <li>
        <Link to="/contact">Contact</Link>
      </li>
    </ul>
  </nav>
);

export default Nav;
